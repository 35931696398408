import * as PIXI from "pixi.js";
import * as filters from "pixi-filters";
import * as PIXIDust from "classes/missioncanvas";
import { Sprite } from "pixi.js";

export class VVPlaceable extends PIXI.Container {

  mOffsetX = 0;
  mOffsetY = 0;
  boardObject = null;
  shadowGraphics = new PIXI.Graphics();
  objectUIGraphics = new PIXI.Graphics();
  trashIcon = null;
  subGraphics = new PIXI.Graphics(); // for selection state
  topGraphics = new PIXI.Graphics(); // for mission refs
  objectArt = null;
  objectDropShadowFilter = null;
  objectOutlineFilter = null;
  infoHex = null;
  infoHexPoint = null;
  infoLabel = null;
  placeAngleIndex = 0;
  objectCoords = null;
  objectRotation = 0;
  objectPosition = new PIXI.Point(0,0);
  objectDataId = null;
  objectGridHex = null;
  objectPivotGridHex = null;
  objectGridFacing = null;
  objectZOrder = 1000;
  objectProperties = null;
  objectPropertyUI = null;
  snap = true;

  constructor(objectId, missionView, spawnLocation, spawnRotation, objectProperties) {
    super();

    // TODO: change rand to UID
    this.name = objectId + ' ' + Math.random();
    this.objectDataId = objectId;

    let pivotPoint = null;
    let minX = 0;
    let maxX = 0;
    let minY = 0;
    let maxY = 0;
    let gridIndex = 0;
    let rotateIcon = new PIXI.Sprite(PIXIDust.appResources.rotateicon.texture);

    // location from new object drop, touch or load
    if (spawnLocation != null) {
      this.objectPosition = spawnLocation;
    }

    // object drop shadow
    // TODO: Move to global definition 
    this.objectDropShadowFilter = new filters.DropShadowFilter();
    this.objectDropShadowFilter.color = 0x000000;
    this.objectDropShadowFilter.blur = 3;
    this.objectDropShadowFilter.distance = 0;

    // selection outline filter
    // TODO: Move to global definition
    this.objectOutlineFilter = new filters.OutlineFilter(3, 0xff0000);

    // find object in data
    try {
        this.boardObject = PIXIDust.getObjectById(objectId);
        this.objectArt = PIXI.Sprite.from(
            "/content/game-assets/objects/" + this.boardObject.art
        );
    } catch (er) {
        PIXIDust.debugLog("Error Finding Object", er);
        return null;
    }

    if (objectProperties != null) {
        this.objectProperties = objectProperties;
    } else {
        this.objectProperties = this.boardObject.properties;
    }

    //snap
    if (this.boardObject.snap != null) {
        this.snap = this.boardObject.snap;
    }
    
    // which direction should the placement arrow
    this.placeAngleIndex = PIXIDust.angleMap[this.boardObject.placementAngle];

    // object information for location & facing
    // TODO: Move style to global definition
    this.infoLabel = new PIXI.Text("REF", {
        fontFamily: "Verdana",
        fontSize: 14,
        fontWeight: 'bold',
        fill: 0xffffff,
        align: "center",
      });
    this.infoLabel.pivot = new PIXI.Point(this.infoLabel.width/2,this.infoLabel.height/2);
    this.infoLabel.x = 0;
    this.infoLabel.y = 0;

    // rotation ui
    rotateIcon.width = 60;
    rotateIcon.height = 60;  
    this.objectUIGraphics.addChild(rotateIcon);

    // build object from definition
    for (let y = 0; y < this.boardObject.height; y++) {
      for (let x = 0; x < this.boardObject.width; x++) {
        if (this.boardObject.grid[gridIndex] === 1) {
          let hexPoint = PIXIDust.getHexPointByCoord(
            gridIndex,
            this.boardObject
          );

          if (gridIndex === this.boardObject.center) {
            PIXIDust.drawHex(this.objectUIGraphics, hexPoint, 0xffffff, 0.2);
            rotateIcon.position = hexPoint;
            rotateIcon.x = rotateIcon.x-rotateIcon.width/2;
            rotateIcon.y = rotateIcon.y-rotateIcon.height/2;
            pivotPoint = hexPoint;
          }
          PIXIDust.drawHex(this.subGraphics, hexPoint, 0x555555,0.5);
          PIXIDust.drawHex(this.shadowGraphics, hexPoint, 0x55aa22, 0.3);

          if (minX > hexPoint.x - PIXIDust.hexWidth / 2) {
            minX = hexPoint.x - PIXIDust.hexWidth / 2;
          }
          if (maxX < hexPoint.x + PIXIDust.hexWidth / 2) {
            maxX = hexPoint.x + PIXIDust.hexWidth / 2;
          }
          if (minY > hexPoint.y - PIXIDust.hexHeight / 2) {
            minY = hexPoint.y - PIXIDust.hexHeight / 2;
          }
          if (maxY < hexPoint.y + PIXIDust.hexHeight / 2) {
            maxY = hexPoint.y + PIXIDust.hexHeight / 2;
          }
        }
        gridIndex++;
      }
    }

    // scale & position art
    if (this.boardObject.artScale === undefined) {
        this.objectArt.width = maxX - minX;
        this.objectArt.height = maxY - minY;
        this.objectArt.x = minX;
        this.objectArt.y = minY + 1.5; // y fit is offset
    } else {
        this.objectArt.scale = new PIXI.Point(this.boardObject.artScale,this.boardObject.artScale);
        this.objectArt.position = new PIXI.Point(this.boardObject.artPosition[0],this.boardObject.artPosition[1]);
    }

    // add information 
    if (this.boardObject.type !== 'guide') {
        this.infoHex = PIXIDust.drawPlacementArrow(this.topGraphics, this.boardObject); 
        this.infoHex.addChild(this.infoLabel);
    }

    this.subGraphics.filters = [this.objectDropShadowFilter];
    
    this.addChild(this.subGraphics);
    this.addChild(this.objectArt);
    this.renderType();
    this.addChild(this.topGraphics);

    this.interactive = true;
    this.dragging = false;

    this.on("mousedown", this.onDragStart)
      .on("touchstart", this.onDragStart)
      .on("mouseup", this.onDragEnd)
      .on("mouseupoutside", this.onDragEnd)
      .on("touchend", this.onDragEnd)
      .on("touchendoutside", this.onDragEnd)
      .on("mousemove", this.onDragMove)
      .on("touchmove", this.onDragMove);

    this.objectUIGraphics.interactive = true;
    this.objectUIGraphics.visible = false;
    this.objectUIGraphics
      .on("click",  e => this.onObjectUIClick(e))
      .on("touchend",  e => this.onObjectUIClick(e));

    this.pivot = pivotPoint;
    this.shadowGraphics.pivot = pivotPoint;    
    this.objectUIGraphics.pivot = pivotPoint;

    this.shadowGraphics.position = this.objectPosition;
    this.position = this.objectPosition;
    this.objectUIGraphics.position = this.objectPosition;

    // z order
    this.objectZOrder = PIXIDust.zOrderBase - (this.boardObject.height * this.boardObject.width);
    this.shadowGraphics.zIndex = this.objectZOrder;
    this.zIndex = this.objectZOrder + 0.1;
    this.objectUIGraphics.zIndex = this.objectZOrder + 0.2;

    missionView.addChild(this.shadowGraphics);
    missionView.addChild(this);
    missionView.addChild(this.objectUIGraphics);

    missionView.sortChildren();

    // trash icon
    this.trashIcon = new PIXI.Sprite(PIXIDust.appResources.trashicon.texture);   
    this.trashIcon.interactive = true;
    this.trashIcon.width = 30;
    this.trashIcon.height = 30;    
    this.trashIcon.pivot = new PIXI.Point(this.trashIcon.width/2,this.trashIcon.height/2);
    this.trashIcon.x = this.infoLabel.width/2;     
    this.trashIcon.y = 35;   
    this.addChild(this.trashIcon); 
    this.trashIcon.visible = false;
    this.trashIcon
      .on("mousedown", (e) => {e.stopPropagation()})
      .on("touchstart", (e) => {e.stopPropagation()})
      .on("click",  e => this.onTrashClick(e))
      .on("touchend",  e => this.onTrashClick(e));

    this.snapToHex();
    this.updateRef();

    if (spawnRotation != null) {
      this.rotateTo(spawnRotation);
    }

    PIXIDust.saveMission(true);
  }

  renderType() {
    if (this.objectPropertyUI == null) {
        this.objectPropertyUI = new PIXI.Sprite();
        this.objectPropertyUI.visible = false;
        this.addChild(this.objectPropertyUI);
    }

    if (this.boardObject.type === "guide") {
        while (this.objectPropertyUI.children[0]) {
            this.objectPropertyUI.removeChild(this.objectPropertyUI.children[0]);
        }
        let guideNum = new PIXI.Text(this.objectProperties.filter(prop => prop.id === "id")[0].value, {
            fontFamily: "Verdana",
            fontSize: 40,
            fontWeight: 'bold',
            fill: 0xffffff,
            align: "center",
        });
        guideNum.pivot = new PIXI.Point(guideNum.width/2,guideNum.height/2);
        this.objectPropertyUI.addChild(guideNum);
        this.objectPropertyUI.visible = true;
    }
  }

  onDragStart(e) {  
    if (PIXIDust.missionLocked) {
        return;
    }  
    PIXIDust.activateSelection(this);
    let newPosition = e.data.getLocalPosition(this.parent, e.data.global);
    this.mOffsetX = newPosition.x - this.x;
    this.mOffsetY = newPosition.y - this.y;

    this.dragging = true;
    PIXIDust.viewport.pause = true;
    this.objectUIGraphics.visible = false;
    if (this.infoHex != null) {
        this.infoHex.visible = false;
    }
  }

  onDragMove(e) {
    if (PIXIDust.missionLocked) {
        return;
    }  
    if (this.dragging) {
      let newPosition = e.data.getLocalPosition(this.parent, e.data.global);

      this.x = newPosition.x - this.mOffsetX;
      this.y = newPosition.y - this.mOffsetY;

      let xIndex = parseInt(this.x / PIXIDust.hexWidth);
      let yIndex = parseInt((this.y / PIXIDust.hexHeight) * 1.34);

      if (this.snap) {
         this.shadowGraphics.position = PIXIDust.snapToGrid(this.position);
      } else {
        this.shadowGraphics.position = this.position;
      }
    }
  }

  onDragEnd(e) {
    if (PIXIDust.missionLocked) {
        return;
    }  
    this.x = this.shadowGraphics.x;
    this.y = this.shadowGraphics.y;

    this.dragging = false;
    PIXIDust.viewport.pause = false;

    this.objectUIGraphics.visible = true;
    this.objectUIGraphics.position = this.position;

    if (this.infoHex != null) {
        this.infoHex.visible = true;
    }

    this.updateRef();

    PIXIDust.loadObjectProperties(this.objectProperties);

    PIXIDust.saveMission(true);
  }

  snapToHex() {
    PIXIDust.debugLog('snapToHex', this.snap);
    let xIndex = parseInt(this.x / PIXIDust.hexWidth);
    let yIndex = parseInt((this.y / PIXIDust.hexHeight) * 1.34);
    
    if (this.snap) {
        this.x = xIndex * PIXIDust.hexWidth + 8 + (yIndex % 2 ? PIXIDust.hexWidth / 2 : 0);
        this.y = yIndex * (PIXIDust.hexHeight * 0.75) + 8;
    }

    this.shadowGraphics.x = this.x;
    this.shadowGraphics.y = this.y;
  }

  updateRef() {
    if (this.boardObject.type === 'guide') {        
        this.objectUIGraphics.visible = false;
    }
    this.objectGridHex = PIXIDust.getBoardCoordByPlacement(this,this.infoHex,this.rotation);
    this.objectPivotGridHex = PIXIDust.getBoardCoordByPlacement(this,null,this.rotation);
    this.objectGridFacing = Object.keys(PIXIDust.angleMap).find(key => PIXIDust.angleMap[key] === this.placeAngleIndex);

    this.infoLabel.text = this.objectGridHex.x+"-"+this.objectGridHex.y+"\n"+this.objectGridFacing;
    this.infoLabel.rotation = -this.rotation;
    this.infoLabel.pivot = new PIXI.Point(this.infoLabel.width/2,this.infoLabel.height/2);
    this.infoLabel.x = 0;
    this.infoLabel.y = 0;
    
    this.trashIcon.x = this.trashIcon.width/5;
  }

  onObjectUIClick(e) {
    if (PIXIDust.missionLocked) {
        return;
    }  
    this.objectRotation++;
    if (this.objectRotation > 5) {
        this.objectRotation = 0;
    }
    this.rotateTo(this.objectRotation);
    PIXIDust.saveMission(true);
  }

  select() {    
    if (PIXIDust.missionLocked) {
        return;
    }  
    this.objectUIGraphics.visible = true;
    this.subGraphics.filters = [this.objectOutlineFilter];
    this.trashIcon.visible = true;
  }
  
  deselect() {
    this.objectUIGraphics.visible = false;
    this.subGraphics.filters = [this.objectDropShadowFilter];
    this.trashIcon.visible = false;
    PIXIDust.resetObjectProperties();
  }

  onTrashClick(e) {
    e.stopPropagation();
    this.destroyObject();
  }

  destroyObject() {
    this.objectUIGraphics.destroy();
    this.shadowGraphics.destroy();
    this.destroy();
    delete PIXIDust.missionDef[this.name];
    PIXIDust.saveMission(true);
  }

  rotateTo(rotateAngleIndex) {
    this.objectRotation = rotateAngleIndex;
    if (this.objectRotation > 5) {
        this.objectRotation = 0;
    }
    
    this.rotation = this.objectRotation * PIXIDust.hexAngle;
    this.shadowGraphics.rotation = this.objectRotation * PIXIDust.hexAngle;
    this.objectUIGraphics.rotation = this.objectRotation * PIXIDust.hexAngle;   
    
    this.placeAngleIndex--;
    if (this.placeAngleIndex < 1) {
        this.placeAngleIndex = 6;
    }

    this.updateRef();    
  }
}
