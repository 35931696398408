import React from 'react';

import Container from 'react-bootstrap/Container';
import Row from 'react-bootstrap/Row';
import Col from 'react-bootstrap/Col';

export default function VVPage(props) {
    const id = props.id;

    return (
       
        <Container id={id} fluid>
            <Row className={'app-container'}>
                <Col>
                    {props.children}
                </Col>
            </Row>            
        </Container>

    );
}