import * as PIXI from "pixi.js";
import * as filters from "pixi-filters";
import { Viewport } from "pixi-viewport";
import { VVPlaceable } from "classes/vv.mission/vv.placeable";

import { Renderer, Sprite } from "pixi.js";

export const name = "PIXIDust";

export const hexWidth = 64.0;
export const hexHeight = hexWidth * 1.1547005;
export const hexRadius = hexHeight / 2;
export const hexAngle = 1.0472;
export const hexGridWidth = 45;
export const hexGridHeight = 47;
export const angleMap = { B: 1, A: 2, F: 3, E: 4, D: 5, C: 6 };
export const mOffsetX = 0;
export const mOffsetY = 0;
export const zOrderBase = 2000;

export let viewport = null;
export let selectedObject = null;
export let missionDef = null;
export let missionNotes = null;
export let appResources = null;
export let missionLocked = false;
export let missionRules = "";

const gridStartX = 536;
const gridStartY = 489;

const DEBUG = false;

let app = null;
let objectData = null;
let missionSaveData = null;
let tableContainer = null;
let toolLayer = null;
let toolLocationSprite = null;
let canvas = null;
let missionID = null;
let missionDT = null;
let missionViewFunctions = null;
let updateMissionList = null;
let lockMission = null;
let loadObjectProps = null;
let resetObjectProps = null;
let titleElement = null;
let thumbPromise = null;
let highlightStatus = false;
let updateMissionRules = null;
let urlMission = null;


// visualizing canvas issues - DEBUG
let utilHex = null;

export function InitPixi(rMissionFunctions, rMissionSaveData, rUrlMission) {
    debugLog("PIXIDust Init");

    urlMission = rUrlMission;

    missionSaveData = rMissionSaveData;
    missionViewFunctions = rMissionFunctions;

    updateMissionList = missionViewFunctions["updateMissionList"];
    lockMission = missionViewFunctions["lockMission"];
    loadObjectProps = missionViewFunctions["loadObjectProps"];
    resetObjectProps = missionViewFunctions["resetObjectProps"];
    updateMissionRules = missionViewFunctions["updateMissionRules"];

    resetMission();

    // object data
    // TODO: move to vv-data
    fetch("/content/data/objects.json", {
        headers: {
            "Content-Type": "application/json",
            Accept: "application/json",
        },
    })
        .then(function (response) {
            debugLog("PIXIDust Objects Preloaded");
            return response.json();
        })
        .then(function (jsondata) {
            objectData = jsondata;
            readyPixi();
        });
}

function readyPixi() {

    canvas = document.getElementById("canvas");
    if (app == null) {
        
        app = new PIXI.Application({ resizeTo: canvas });

        // required to save thumbnails
        app.renderer = new Renderer({preserveDrawingBuffer: true});    
        //app.renderer = PIXI.autoDetectRenderer(850, 1100,{ preserveDrawingBuffer: true, view:canvas, antialias:true, resolution: window.devicePixelRatio || 1, autoResize:false});
     
        resizeCanvas();

        // future animations
        app.ticker.add((delta) => {
   
        });

        // pixi-viewport
        // https://www.npmjs.com/package/pixi-viewport        
        viewport = new Viewport({
            screenWidth: canvas.clientWidth,
            screenHeight: canvas.clientHeight,
            worldWidth: 1200,
            worldHeight: 1200,
            interaction: app.renderer.plugins.interaction,
        });
                
        app.stage.addChild(viewport);
        viewport.drag().pinch().wheel().decelerate();
        
    } else {
        app.ticker.start();
    }

    canvas.appendChild(app.view);
    canvas.removeEventListener("drop", onCanvasDrop);
    canvas.removeEventListener("dragover", onCanvasDragOver);    
    canvas.removeEventListener("drop", onCanvasDrop);
    canvas.removeEventListener("dragover", onCanvasDragOver);    
    canvas.removeEventListener("drop", onCanvasDrop);
    canvas.removeEventListener("dragover", onCanvasDragOver);    
    canvas.addEventListener("drop", onCanvasDrop);
    canvas.addEventListener("dragover", onCanvasDragOver);  

    // Mission Title handler
    // TODO: move to react
    titleElement = document.getElementById("mission-title");    
    titleElement.addEventListener("paste", function (e) {
        setTimeout(function () {
            titleElement.innerHtml = titleElement.innerText;
        }, 0);
    });
    titleElement.addEventListener("keypress", function (e) {
        if (e.key === "Enter") {
            e.preventDefault();
            titleElement.blur();
        }
    });
    titleElement.addEventListener("input", function (e) {
        saveMission();
    });

    baseScene();   
}

function resetMission() {

    // clean persistent DOM
    if (document.getElementById("canvas") != null && document.getElementById("canvas") !== undefined) {
        document.getElementById("canvas").removeEventListener("drop", onCanvasDrop);
        document.getElementById("canvas").removeEventListener("dragover", onCanvasDragOver);   
    }

    //app = null;
    objectData = null; 
    tableContainer = null;
    utilHex = null;
    canvas = null;
    viewport = null;
    selectedObject = null; 
    missionDef = [];
    missionNotes = [];
    appResources = null;
    missionID = null;
    missionDT = new Date();
    missionLocked = false;

}

function baseScene() {
    app.loader
        .add(
            "board",
            "/content/game-assets/velocity-betaboard-wide-standard-01-half.png"
        )
        .add("trashicon", "/content/game-assets/ui/SVG/trash-object.svg")
        .add("rotateicon", "/content/game-assets/ui/SVG/rotate-object.svg")
        .load((loader, resources) => {
            appResources = resources;
            tableContainer = new PIXI.Container();

            let board = new PIXI.Sprite(resources.board.texture);
            board.interactive = true;
            board
                .on("click", () => activateSelection(null))
                .on("touchend", () => activateSelection(null))
                .on("click", e => editMode(e))
                .on("touchend", e => editMode(e));;

            tableContainer.addChild(board);
            viewport.addChild(tableContainer);

            tableContainer.x =
                app.renderer.width / 2 - tableContainer.width / 2;
            tableContainer.y =
                app.renderer.height / 2 - tableContainer.height / 2;
            tableContainer.interactive = true;
            tableContainer.sortableChildren = true;

            board.on("mousemove", e => tableCursor(e));

            // use for debugging
            //utilHex = new PIXI.Graphics();
            //drawHex(utilHex, new PIXI.Point(0,0), 0xFF0000, 1, 0xFFFF00, 5);
            //app.stage.addChild(utilHex);

            

            toolLocationSprite = new PIXI.Sprite();
            let toolLocationGraphics = new PIXI.Graphics();
            toolLocationSprite.addChild(toolLocationGraphics);
            drawHex(toolLocationGraphics, new PIXI.Point(0,0), 0x55aa22, 1);

            toolLayer = new PIXI.Sprite();
            toolLayer.addChild(toolLocationSprite);

            let toolBorderFilter = new filters.OutlineFilter(3, 0xffFF00);     
            let toolAlphaFilter = new PIXI.filters.AlphaFilter(0.5);     

            toolLayer.filters = [toolBorderFilter,toolAlphaFilter];
            toolLayer.blendMode = PIXI.BLEND_MODES.SCREEN;
            //toolLayer.position = board.position;
            board.addChild(toolLayer);

            loadMission(urlMission);

            window.onbeforeprint = function() {                
                //app.renderer.resize(850, 1100);
            }
            
            viewport.zoomPercent(-0.25,true);            

            debugLog("PIXIDust Ready");
        });
}

export function renderGrid() {
    // Use PIXI to render grid
    // TODO: reimplement so safe for cacheAsBitmap  
    //return;

    let graphics = new PIXI.Graphics();
    for (let v = 10.0; v < hexGridHeight; v++) {
        for (let h = 10.0; h < hexGridWidth; h++) {
            //debugLog(h + " - " + v);
            let gridLabel = new PIXI.Text(h + " - " + v, {
                fontFamily: "Arial",
                fontSize: 11,
                fill: 0xffffff,
                align: "left",
            });

            let xOffset = hexWidth * h - gridStartX;
            let yOffset = hexHeight * 0.75 * v - gridStartY;
            if (v % 2 > 0) {
                xOffset = xOffset + hexWidth / 2;
            }
            let hexPoint = new PIXI.Point(xOffset, yOffset);

            gridLabel.x = hexPoint.x - gridLabel.width / 2;
            gridLabel.y = hexPoint.y - gridLabel.height / 2;
            tableContainer.addChild(gridLabel);

            //drawHex(graphics, hexHeight / 2, hexPoint);
            //debugLog(hexPoint);
            drawHex(graphics, hexPoint, null, null, 0xff00ff, 3);
        }
    }
    tableContainer.addChild(graphics);
}

export function drawHex(
    surface,
    point,
    fillColor,
    fillOpacity,
    strokeColor,
    strokeWeight
) {
    let startPoint = [0, -hexRadius];

    let hexPoint = new PIXI.Point(0, 0);
    if (point != null) {
        hexPoint = point;
    }

    let hexOpacity = 1;
    if (fillOpacity != null) {
        hexOpacity = fillOpacity;
    }

    let hexFill = null;
    if (fillColor != null) {
        hexFill = fillColor;
    }

    let hexStrokeColor = 0x000000;
    if (strokeColor != null) {
        hexStrokeColor = strokeColor;
    }

    let hexStrokeWeight = 0;
    if (strokeWeight != null) {
        hexStrokeWeight = strokeWeight;
    }

    if (hexFill != null) {
        surface.beginFill(hexFill, hexOpacity);
    }
    if (hexStrokeWeight > 0) {
        surface.lineStyle(hexStrokeWeight, hexStrokeColor);
        surface.lineStyle.alignment = 1;
    }

    surface.drawPolygon(
        startPoint[0] + hexPoint.x,
        startPoint[1] + hexPoint.y,
        rotateVector(startPoint, 60)[0] + hexPoint.x,
        rotateVector(startPoint, 60)[1] + hexPoint.y,
        rotateVector(startPoint, 120)[0] + hexPoint.x,
        rotateVector(startPoint, 120)[1] + hexPoint.y,
        rotateVector(startPoint, 180)[0] + hexPoint.x,
        rotateVector(startPoint, 180)[1] + hexPoint.y,
        rotateVector(startPoint, 240)[0] + hexPoint.x,
        rotateVector(startPoint, 240)[1] + hexPoint.y,
        rotateVector(startPoint, 300)[0] + hexPoint.x,
        rotateVector(startPoint, 300)[1] + hexPoint.y
    );
    surface.endFill();
}

export function rotateVector(vec, ang) {
    ang = -ang * (Math.PI / 180);
    let cos = Math.cos(ang);
    let sin = Math.sin(ang);
    return [
        Math.round(10000 * (vec[0] * cos - vec[1] * sin)) / 10000,
        Math.round(10000 * (vec[0] * sin + vec[1] * cos)) / 10000
    ];
}

export function placeObject(objectID, placePoint, placeRotation, objectProperties, fromLoad) {
    let pObject = new VVPlaceable(
        objectID,
        tableContainer,
        placePoint,
        placeRotation,
        objectProperties
    );
    missionDef[pObject.name] = pObject;
    if (!fromLoad || fromLoad == null) {
        saveMission(true);
    }
}

export function placeObjectAtCenter(objectID) {
    let dropXPercent = 0.5;
    let dropYPercent = 0.5;

    let globalDropX =
        viewport.screenWidthInWorldPixels * dropXPercent * viewport.scale.x;
    let globalDropY =
        viewport.screenHeightInWorldPixels * dropYPercent * viewport.scale.y;

    let tempPoint = new PIXI.Point(globalDropX, globalDropY);

    placeObject(
        objectID,
        new PIXI.Point(
            tableContainer.toLocal(tempPoint).x,
            tableContainer.toLocal(tempPoint).y
        )
    );
}

export function loadObjectProperties(objectProps) {
    debugLog("loadObjectProperties", objectProps);
    loadObjectProps(objectProps);
}

export function resetObjectProperties() {
    debugLog("resetObjectProperties");
    resetObjectProps();
}

export function updateObjectProperties(newProps) {
    debugLog('updateObjectProperties', selectedObject, newProps);
    selectedObject.objectProperties = Array.from(newProps);
    selectedObject.renderType();
    saveMission(true);
}

export function getBoardCoordByPlacement(objectSub, infoHex, objectRotation) {
    let objectPoint = objectSub.position;
    let objectPivotPoint = objectSub.pivot;
    let absoluteInfoPoint = new PIXI.Point(0,0);

    if (infoHex != null) {
        let infoHexPoint = infoHex.position;
        let infoHexPointArray = [
            infoHexPoint.x - objectPivotPoint.x,
            infoHexPoint.y - objectPivotPoint.y,
        ];
        let pi = Math.PI;
        let objectRotationDegrees = objectRotation * -(180 / pi);
        absoluteInfoPoint = new PIXI.Point(
            rotateVector(infoHexPointArray, objectRotationDegrees)[0],
            rotateVector(infoHexPointArray, objectRotationDegrees)[1]
        );
    }

    let placementPoint = new PIXI.Point(
        objectPoint.x + absoluteInfoPoint.x,
        objectPoint.y + absoluteInfoPoint.y
    );

    return new getBoardCoordsRef(placementPoint);
}

export function getBoardCoordsRef(pixiCoords) {
    let hexCoords = getNearestBoardIndex(pixiCoords);    
    hexCoords.x = hexCoords.x+9;
    hexCoords.y = hexCoords.y+9;
    if (hexCoords.y % 2 > 0) {
        hexCoords.x--;
    }
    return hexCoords;
}

export function getBoardCoords(pixiCoords) {
    let hexCoords = getNearestBoardIndex(pixiCoords);    
    if (hexCoords.y % 2 === 0) {
        hexCoords.x++;
    }
    return hexCoords;
}

export function getBoardIndexFromCoords(boardCoords) {
    boardCoords.x = boardCoords.x-9;
    boardCoords.y = boardCoords.y-9;
    if (boardCoords.y % 2 === 0) {
        boardCoords.x++;
    }
    return boardCoords;
}

export function getNearestBoardIndex(pixiCoords) {
    let xIndex = parseInt((pixiCoords.x-(hexWidth*0.1)) / hexWidth);
    let yIndex = parseInt(((pixiCoords.y+(hexWidth/4)) / hexHeight) * 1.34);

    if (yIndex % 2 === 0) {        
        xIndex = parseInt((pixiCoords.x+(hexWidth*0.3)) / hexWidth);
    }

    return new PIXI.Point(xIndex,yIndex);
}

export function snapToGrid(pixiCoords) {
    let boardIndex = getNearestBoardIndex(pixiCoords)
    return getPixiCoordsFromIndex(boardIndex);
}

export function getPixiCoordsFromIndex(boardIndex) {
    let snappedX = boardIndex.x * hexWidth + 8 + (boardIndex.y % 2 ? hexWidth / 2 : 0);
    let snappedY = boardIndex.y * (hexHeight * 0.75) + 8;
    return new PIXI.Point(snappedX,snappedY);
}


export function drawPlacementArrow(graphics, bObject) {
    // draw point mark
    let hexPoint = getHexPointByCoord(bObject.placementHex, bObject);

    //which angle?
    let facingEdge = angleMap[bObject.placementAngle];

    // literal placement angle
    let infoHexGraphic = new PIXI.Graphics();
    let placementAngle = 60 * facingEdge - 30;
    let infoHexPointRef = [0, -hexWidth];
    let infoHexPoint = new PIXI.Point(0, 0);

    infoHexPoint.x = rotateVector(infoHexPointRef, placementAngle)[0];
    infoHexPoint.y = rotateVector(infoHexPointRef, placementAngle)[1];
    drawHex(infoHexGraphic, new PIXI.Point(0, 0), 0x000000, 0.8, 0xffff00, 3);
    infoHexGraphic.x = hexPoint.x + infoHexPoint.x;
    infoHexGraphic.y = hexPoint.y + infoHexPoint.y;
    infoHexGraphic.scale = new PIXI.Point(1.1,1.1);
    graphics.addChild(infoHexGraphic);
    

    // arrow
    let arrowPointA = [10, -20];
    let arrowPointB = [0, -32];
    let arrowPointC = [-10, -20];
    graphics.beginFill(0xffffff, 1);
    graphics.drawPolygon(
        hexPoint.x + rotateVector(arrowPointA, placementAngle)[0],
        hexPoint.y + rotateVector(arrowPointA, placementAngle)[1],
        hexPoint.x + rotateVector(arrowPointB, placementAngle)[0],
        hexPoint.y + rotateVector(arrowPointB, placementAngle)[1],
        hexPoint.x + rotateVector(arrowPointC, placementAngle)[0],
        hexPoint.y + rotateVector(arrowPointC, placementAngle)[1]
    );
    graphics.endFill();

    return infoHexGraphic;
}

export function activateSelection(selected) {
    titleElement.blur();
    if (selectedObject != null) {
        selectedObject.deselect();
    }
    if (selected != null) {
        selectedObject = selected;
        selectedObject.select();
    }
}

export function getHexPointByCoord(gridIndex, bObject) {
    // which hex?
    let markY = Math.trunc(gridIndex / bObject.width);
    let markX =
        gridIndex - Math.trunc(gridIndex / bObject.width) * bObject.width;
    let xOffset = hexWidth * markX;
    let yOffset = hexHeight * 0.75 * markY;
    if (markY % 2 > 0) {
        xOffset = xOffset + hexWidth / 2;
    }
    return new PIXI.Point(xOffset, yOffset);
}

export function getObjectById(id) {
    let objectList = objectData.objects;
    if (objectList === null) {
        return null;
    }
    return objectList.filter(function (objects) {
        return objects.id === id;
    })[0];
}

export function DestroyPixi() {
    debugLog("PIXI Destroy");
    resetMission();
    if (app != null) {
        app.destroy(true, true);
        app = null;
    }
}

export function saveMission(genThumb) {
   
    let missionTitle = document.getElementById("mission-title").innerText;

    let missionSaveDef = {
        id: missionID,
        name: missionTitle,
        rules: missionRules,
        missionObjects: [],
        missionNotes: []
    };

    for (var Nkey in missionNotes) {
        let missionNote = missionNotes[Nkey];
        let missionNoteDef = {
            name: Nkey,
            type: missionNote.type,
            highlightGridPosition: [
                missionNote.highlightGridPosition[0],
                missionNote.highlightGridPosition[1],
            ]
        };    
        missionSaveDef.missionNotes.push(missionNoteDef);
    }

    for (var key in missionDef) {
        let missionObjectName = key;
        let missionObject = missionDef[key];

        let missionObjectDef = {
            name: missionObjectName,
            objectID: missionObject.objectDataId,
            type: missionObject.boardObject.type,
            class: missionObject.boardObject.class,
            objectPosition: [
                missionObject.position.x,
                missionObject.position.y,
            ],
            objectGridPosition: [
                missionObject.objectGridHex.x,
                missionObject.objectGridHex.y,
            ],
            objectPivotGridPosition: [
                missionObject.objectPivotGridHex.x,
                missionObject.objectPivotGridHex.y,
            ],
            objectGridFacing: missionObject.objectGridFacing,
            objectRotation: missionObject.objectRotation,
            properties: missionObject.objectProperties
        };

        missionSaveDef.missionObjects.push(missionObjectDef);
    }

    // set Download attachment
    let dButton = document.getElementById("download-button");
    dButton.setAttribute(
        "href",
        "data:application/vv+json;charset=utf-8," +
            encodeURIComponent(JSON.stringify(missionSaveDef))
    );
    dButton.setAttribute("download", missionTitle + ".vv");
    dButton.setAttribute("data-filename", missionTitle + ".vv");

    if (missionLocked) {
        return;
    }

    // set local storage
    localStorage.setItem("vv-last-mission", missionID);
    localStorage.setItem(missionID, JSON.stringify(missionSaveDef));

    //update sessiondata
    let existingMission = false;
    if (missionSaveData.missions != null) {

        for (
            let missionIndex = 0;
            missionIndex < missionSaveData.missions.length;
            missionIndex++
        ) {
            let curMission = missionSaveData.missions[missionIndex];
            
            if (curMission.id === missionID) {
                existingMission = true;
                curMission.name = missionSaveDef.name;
                missionSaveData.missions[missionIndex] = missionSaveDef;
                updateMissionList();
            }
        }
        if (!existingMission) {
            missionSaveData.missions.push(missionSaveDef);
            updateMissionList();
        }
    }

    if (genThumb) {
        genMissionThumbnail();
    }
}

export function newMission() {
    missionDT = new Date();
    missionID = "vv-mission-" + missionDT.getTime();
    localStorage.setItem("vv-last-mission", missionID);
    DestroyPixi();
    InitPixi(missionViewFunctions, missionSaveData);

    //TODO: move to react
    document.getElementById("mission-title").innerText = "Untitled";
}

export function deleteMission() {
    if (missionID != null) {
        localStorage.removeItem(missionID);
        localStorage.removeItem(missionID + "-thumb");
        if (missionSaveData.missions != null) {
            let deleteIndex = null;
            for (
                let missionIndex = 0;
                missionIndex < missionSaveData.missions.length;
                missionIndex++
            ) {
                let curMission = missionSaveData.missions[missionIndex];
                if (curMission.id === missionID) {
                    deleteIndex = missionIndex;
                }
            }
            missionSaveData.missions.splice(deleteIndex, 1);
        }
    }
    updateMissionList();
    newMission();
}

export function uploadMission(missionSource) {
    if (missionSource != null) {
        // TODO: Validate mission format before save
        missionDT = new Date();
        missionID = "vv-mission-" + missionDT.getTime();
        localStorage.setItem(missionID, missionSource);
        loadMission(missionID);
    }
}

export function clearMission() {
    while (tableContainer.children[1]) {
        tableContainer.removeChild(tableContainer.children[1]);
    }
    while (toolLayer.children[1]) {
        toolLayer.removeChild(toolLayer.children[1]);
    }
    //renderGrid();
    missionDef = [];
    missionNotes = [];
}

export function loadMission(loadMissionId) {
    clearMission();
    missionID = loadMissionId;
    if (missionID == null) {
        missionID = localStorage.getItem("vv-last-mission");
    }
    if (missionID === null || missionID === "") {
        missionID = "vv-mission-" + missionDT.getTime();
    }
    let missionJSON = localStorage.getItem(missionID);
    if (missionJSON === null || missionJSON === "") {
        return;
    }
    let missionSaveDef = JSON.parse(missionJSON);
    
    if (missionSaveDef.locked === undefined) {
        missionLocked = false;
    } else {
        missionLocked = missionSaveDef.locked;
    }

    document.getElementById("mission-title").innerText = missionSaveDef.name;
    missionRules =  missionSaveDef.rules != null ? missionSaveDef.rules : "";
    updateMissionRules(missionSaveDef.name, missionRules, missionLocked);
    if (missionLocked) {
        document.getElementById("mission-title").contentEditable = "false";
        lockMission(true);
    } else {
        document.getElementById("mission-title").contentEditable = "true";
        lockMission(false);
    }
    
    missionSaveDef.missionObjects.forEach((missionObject) => {
        placeObject(
            missionObject.objectID,
            new PIXI.Point(
                missionObject.objectPosition[0],
                missionObject.objectPosition[1]
            ),
            missionObject.objectRotation,
            missionObject.properties,
            true
        );
    });

    if (missionSaveDef.missionNotes != null) {
        missionSaveDef.missionNotes.forEach((metaItem) => {
            if (metaItem.type === "highlight") {
                drawHexHighlight(new PIXI.Point(
                    metaItem.highlightGridPosition[0],
                    metaItem.highlightGridPosition[1]
                ), 
                true);
            }
        });
    }

    saveMission();
}

export function rulesChanged(rules) {
    missionRules = rules;
    saveMission();
}

export function resizeCanvas() {   
    app.renderer.resize(canvas.clientWidth, canvas.clientHeight);
}

export function debugLog(...args) {
    if (DEBUG) {
        console.log(args); 
    }
}

function tableCursor(e) {
    let cursorPosition = new PIXI.Point(
        tableContainer.toLocal(e.data.global).x,
        tableContainer.toLocal(e.data.global).y
    )

    if (highlightStatus && !missionLocked) { 
        toolLocationSprite.alpha = 1;

        let snapped = snapToGrid(cursorPosition);
        toolLocationSprite.x = snapped.x;
        toolLocationSprite.y = snapped.y; 
    } else {
        toolLocationSprite.alpha = 0;
    }
    return;
}

function editMode(e) {
    let cursorPosition = new PIXI.Point(
        tableContainer.toLocal(e.data.global).x,
        tableContainer.toLocal(e.data.global).y
    )
    if (highlightStatus && !missionLocked) {
        drawHexHighlight(cursorPosition);
    }
}

export function setHighlightStatus(status) {
    highlightStatus = status;
}

export function drawHexHighlight(highlightPosition, fromLoad) {

    let toolSprite = new PIXI.Sprite();
    let toolGraphics = new PIXI.Graphics();

    toolSprite.addChild(toolGraphics);

    let snapped = snapToGrid(highlightPosition);

    if (fromLoad) {
        snapped = getPixiCoordsFromIndex(getBoardIndexFromCoords(highlightPosition))
    }

    let hexCoords = getBoardCoordsRef(snapped);
    let highlightName = 'highlight ' + hexCoords.x + ',' + hexCoords.y;

    drawHex(toolGraphics, new PIXI.Point(0, 0), 0x000000, 1);

    toolSprite.x = snapped.x;
    toolSprite.y = snapped.y;

    toolSprite.interactive = true;
    toolSprite.on("click", e => deleteHighlight(e,highlightName));
    toolLayer.addChild(toolSprite);

    let boardHighlight = {
        name: highlightName,
        type: 'highlight',
        highlightGridPosition: [
            hexCoords.x,
            hexCoords.y,
        ]
    };

    missionNotes[highlightName] = boardHighlight;
    if (!fromLoad || fromLoad == null) {
        saveMission(true);
    }
}

function deleteHighlight(e,highlightName) {
    if (highlightStatus) {
        toolLayer.removeChild(e.target);
        delete missionNotes[highlightName];
        saveMission(true);
    }
}

function onCanvasDrop(ev) { 
    ev.preventDefault(); 
    let dropXPercent = ev.clientX / canvas.clientWidth;
    let dropYPercent = ev.clientY / canvas.clientHeight;

    let globalDropX =
        viewport.screenWidthInWorldPixels * dropXPercent * viewport.scale.x;
    let globalDropY =
        viewport.screenHeightInWorldPixels * dropYPercent * viewport.scale.y;

    let tempPoint = new PIXI.Point(globalDropX, globalDropY);

    placeObject(
        ev.dataTransfer.getData("object"),
        new PIXI.Point(
            tableContainer.toLocal(tempPoint).x,
            tableContainer.toLocal(tempPoint).y
        )
    );
}

function onCanvasDragOver(ev) {
    ev.preventDefault();
    ev.dataTransfer.dropEffect = "move"; 
}

function genMissionThumbnail() {   

    if (thumbPromise == null) {
        thumbPromise = new Promise((resolve, reject) => {  

            let thumbnailWidth = 120;
            let canvasElement = canvas.firstChild;
            let canvasElWidth = canvas.clientWidth;
            let canvasElHeight = canvas.clientHeight;

            let thumbScale = thumbnailWidth / canvasElWidth;
            let thumbnailHeight = thumbScale * canvasElHeight;            

            let resizedCanvas = document.createElement("canvas");
            let resizedContext = resizedCanvas.getContext("2d");
            
            resizedCanvas.width = thumbnailWidth; 
            resizedCanvas.height = thumbnailHeight;
            
            let image = new Image();
            image.onload = function() {
                resizedContext.drawImage(image, 0, 0, thumbnailWidth, thumbnailHeight);
                let dataURL = resizedCanvas.toDataURL();
                resolve(dataURL);      
            };

            let genImage = async () => {
                setTimeout(() => {
                    image.src = canvasElement.toDataURL('image/jpeg', 0.1);;
                },1000);
            }             
            genImage();
   
        });

        thumbPromise
            .then((c) => {
                saveThumbnail(c);
            }, (c) => {debugLog('thumbnail failed',c)})
            .catch((c) => {
                debugLog('thumbnail exception',c);
            })

    }
}

function saveThumbnail(blob) {
    thumbPromise = null;
    if (blob != null) {
        localStorage.setItem(missionID+'-thumb', blob);
        updateMissionList();
    }
}