import React from 'react';


export default function VVMissionPage(props) {
    const id = props.id;

    return (
       
        <div id={id}>

                    {props.children}
     
        </div>

    );
}