import React from "react";

import Container from "react-bootstrap/Container";
import lockedIcon from './../../img/ui/locked.svg';

export default function MissionList(props) {
    let missionData = props.missionData;
    const PIXIDust = props.pixiDust;

    function getMissionThumbnail(missionID) {
        return localStorage.getItem(missionID+'-thumb');
    }

    function getCustomMissionData() {
        if (missionData !== null && missionData.length > 0) {
            return encodeURIComponent('{"missions":'+JSON.stringify(missionData.filter(mission => mission.locked !== true))+'}');
        } 
        return "{}";
    }

    function MissionItem(mission) {
        if (mission != null && mission.id != null && mission.id !== '') {
            return (<div
                key={mission.id}
                className={"mission-item"}
                onClick={() => {
                    PIXIDust.loadMission(mission.id);                   
                }}
                id={mission.id} style={{backgroundImage: "url('"+getMissionThumbnail(mission.id)+"')"}}
            >
                {mission.locked ? (<img className="locked-icon" src={lockedIcon}/>) : ""}
                <span>{mission.name}</span>
            </div>);
        }
    }

    return (
        <Container className={"mission-list"}>            

            <div className="mission-category" style={{marginTop: 56}} >Game Missions</div>
            {missionData &&
                missionData.length > 0 &&
                missionData.filter(mission => mission.locked === true).sort((a, b) => (a.name.toLowerCase() > b.name.toLowerCase()) ? 1 : -1).map((mission) => (MissionItem(mission)))}

            <div className="mission-category">Custom Missions</div>
            <div id="newmissionbutton" className="new-mission-button"
                onClick={function (e) {
                    PIXIDust.newMission();
                    window.location.reload();
                }}
            >
                Create Mission
            </div>
            {missionData &&
                missionData.length > 0 &&
                missionData.filter(mission => mission.locked != true).sort((a, b) => (a.name.toLowerCase() > b.name.toLowerCase()) ? 1 : -1).map((mission) => (MissionItem(mission)))}

            <a title="Export Missions"
                    id="download-all-button"
                    className="export-missions-button" href={"data:application/vv+json;charset=utf-8,"+getCustomMissionData()} download="my-missions.vv" data-filename="my-missions.vvs"
                >Export All</a>
            <div id="savemissionbutton" className="dropbox-missions-button"
                            onClick={function (e) {
                                
                                var options = {};
                                window.Dropbox.save("data:application/vv+json;charset=utf-8,"+getCustomMissionData(), "my-missions.vvs", options);
                            }}
                        >
                Export to Dropbox
            </div>
        </Container>
    );
}
