import 'styles/App.scss';
import { HashRouter, Route, Routes } from 'react-router-dom';

import LoadData from 'classes/vvdata';

import HeaderUtil from 'components/ui/header-util';
import Landing from 'components/containers/landing';
import Rulebook from 'components/containers/rulebook';
import Ships from 'components/containers/ships';
import Modules from 'components/containers/modules';
import ModuleAttributes from 'components/containers/moduleattributes';
import Missions from 'components/containers/missions';
import Specialists from 'components/containers/specialists';
import Commanders from 'components/containers/commanders';

function App() {
  const appData = LoadData();
  const release = appData.release;

  return (
    <div className="App">
      <HeaderUtil />
      <HashRouter>
        <Routes>
          <Route
            exact
            path='/'
            element={<Landing />}
          />
          <Route
            exact
            path='/rulebook'
            element={<Rulebook />}
          />
          <Route
            exact
            path='/ships'
            element={<Ships appData={appData} />}
          />
          <Route
            exact
            path='/modules'
            element={<Modules appData={appData} />}
          />
          <Route
            exact
            path='/module-attributes'
            element={<ModuleAttributes appData={appData} />}
          />
          <Route
            exact
            path='/specialists'
            element={<Specialists appData={appData} />}
          />
          <Route
            exact
            path='/commanders'
            element={<Commanders appData={appData} />}
          />
          <Route
            exact
            path='/missions'
            element={<Missions appData={appData} />}
          />
          <Route
            exact
            path='/missions/:urlMissionId'
            element={<Missions appData={appData} />}
          />
        </Routes>
      </HashRouter>
      <div id="release-info">&copy; Copyright 2023 Precarious Games LLC. - v{release}</div>
    </div>
  );
}

export default App;
