import React, { useState, useEffect } from "react";

import ReactMarkdown from "react-markdown";
import remarkGfm from "remark-gfm";
import rmDirective from "remark-directive";
import SyntaxHighlighter from "react-syntax-highlighter";
import VisibilityIcon from "@mui/icons-material/Visibility";
import EditIcon from "@mui/icons-material/Edit";
import CloseIcon from "@mui/icons-material/Close";
import { docco } from "react-syntax-highlighter/dist/esm/styles/hljs";

import customDirectives from "classes/remark-vvref";

export default function RuleItemEditor(props) {
    const PIXIDust = props.pixiDust;
    const missionLocked = props.missionLocked;
    const [ruleContent, setRuleContent] = useState("");
    const [editMode, setEditMode] = useState("preview");

    function editing(edit, cEl) {
        if (edit) {
            setEditMode("edit");
        } else {
            let rulesText = cEl.currentTarget.parentElement.querySelector(
                '[contentEditable="true"]'
            ).innerText;
            setRuleContent(rulesText);
            PIXIDust.rulesChanged(rulesText);
            setEditMode("preview");
        }
    }

    useEffect(() => {
        setRuleContent(props.ruleContent);
    }, [props.ruleContent]);

    const components = {
        banana({ text, intensity = 1 }) {
            const fx = "🍌".repeat(intensity);
            return fx + text + fx;
        },
    };

    const customComponents = {
        VVRef({ text }) {
            return <span className="vv-ref"> {text} </span>;
        },
    };

    return (
        <div className="rule-editor">
            <div className={"rules-markdown " + editMode}>
                {missionLocked ? null : (
                    <EditIcon
                        onClick={() => {
                            editing(true);
                        }}
                    />
                )}
                <ReactMarkdown
                    children={ruleContent}
                    components={{ ...components, ...customComponents }}
                    remarkPlugins={[remarkGfm, rmDirective, customDirectives]}
                />
            </div>

            <div className={"rules-editor " + editMode}>
                <CloseIcon
                    onClick={(e) => {
                        editing(false, e);
                    }}
                />
                <pre
                    contentEditable="true"
                    suppressContentEditableWarning="true"
                    className={"editor"}
                >
                    {ruleContent}
                </pre>
            </div>
        </div>
    );
}

const MarkComponent = ({ value, language }) => {
    return (
        <SyntaxHighlighter language={language ?? null} style={docco}>
            {value ?? ""}
        </SyntaxHighlighter>
    );
};
