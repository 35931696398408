import { useState, useEffect } from "react";
import { version, branch } from "autobuild_version";

export default function LoadData() {
    const [shipData, setShipData] = useState([{}]);
    const getShipData = () => {
        fetch("/content/data/ships.json", {
            headers: {
                "Content-Type": "application/json",
                Accept: "application/json",
            },
        })
            .then(function (response) {
                return response.json();
            })
            .then(function (jsonData) {
                setShipData(jsonData);
            });
    };

    const [effectData, setEffectData] = useState([]);
    const getEffectData = () => {
        fetch("/content/data/effects.json", {
            headers: {
                "Content-Type": "application/json",
                Accept: "application/json",
            },
        })
            .then(function (response) {
                return response.json();
            })
            .then(function (jsonData) {
                //hash
                let effectRef = [];
                if (jsonData.effects && jsonData.effects.length > 0) {
                    jsonData.effects.forEach((effect) => {
                        effectRef[effect.id] = effect;
                    });
                }
                setEffectData(effectRef);
            });
    };

    const [specialistsData, setSpecialistsData] = useState([{}]);
    const getSpecialistsData = () => {
        fetch("/content/data/specialists.json", {
            headers: {
                "Content-Type": "application/json",
                Accept: "application/json",
            },
        })
            .then(function (response) {
                return response.json();
            })
            .then(function (jsonData) {
                setSpecialistsData(jsonData);
            });
    };

    const [commanderData, setCommanderData] = useState([{}]);
    const getCommanderData = () => {
        fetch("/content/data/commanders.json", {
            headers: {
                "Content-Type": "application/json",
                Accept: "application/json",
            },
        })
            .then(function (response) {
                return response.json();
            })
            .then(function (jsonData) {
                setCommanderData(jsonData);
            });
    };

    const [objectData, setObjectData] = useState([{}]);
    const getObjectData = () => {
        fetch("/content/data/objects.json", {
            headers: {
                "Content-Type": "application/json",
                Accept: "application/json",
            },
        })
            .then(function (response) {
                return response.json();
            })
            .then(function (jsonData) {
                setObjectData(jsonData);
            });
    };

    const [objectTypeData, setObjectTypeData] = useState([{}]);
    const getObjectTypeData = () => {
        fetch("/content/data/object-types.json", {
            headers: {
                "Content-Type": "application/json",
                Accept: "application/json",
            },
        })
            .then(function (response) {
                return response.json();
            })
            .then(function (jsonData) {
                setObjectTypeData(jsonData);
            });
    };

    const [factionData, setFactionData] = useState([{}]);
    const getFactionData = () => {
        fetch("/content/data/factions.json", {
            headers: {
                "Content-Type": "application/json",
                Accept: "application/json",
            },
        })
            .then(function (response) {
                return response.json();
            })
            .then(function (jsonData) {
                setFactionData(jsonData);
            });
    };

    const [missionData, setMissionData] = useState([{}]);
    const getMissionData = () => {
        fetch("/content/data/missions.json", {
            headers: {
                "Content-Type": "application/json",
                Accept: "application/json",
            },
        })
            .then(function (response) {
                return response.json();
            })
            .then(function (jsonData) {
                // clear locked missions
                for (var key in localStorage) {
                    if (
                        key.indexOf("vv-mission") > -1 &&
                        key.indexOf("-thumb") < 0
                    ) {
                        var tempMission = JSON.parse(localStorage.getItem(key));
                        if (tempMission.locked === true) {
                            localStorage.removeItem(key);
                            localStorage.removeItem(key + "-thumb");
                        }
                    }
                }

                jsonData.missions.forEach((mission) => {
                    localStorage.setItem(mission.id, JSON.stringify(mission));
                });

                let allMissionData = { missions: [] };
                for (var mKey in localStorage) {
                    if (
                        mKey.indexOf("vv-mission") > -1 &&
                        mKey.indexOf("-thumb") < 0
                    ) {
                        allMissionData.missions.push(
                            JSON.parse(localStorage.getItem(mKey))
                        );
                    }
                }

                setMissionData(allMissionData);
            });
    };

    const [moduleData, setModuleData] = useState([{}]);
    const getModuleData = () => {
        fetch("/content/data/modules.json", {
            headers: {
                "Content-Type": "application/json",
                Accept: "application/json",
            },
        })
            .then(function (response) {
                return response.json();
            })
            .then(function (jsonData) {
                setModuleData(jsonData);
            });
    };

    const [moduleAttributeData, setModuleAttributeData] = useState([{}]);
    const getModuleAttributeData = () => {
        fetch("/content/data/module-attributes.json", {
            headers: {
                "Content-Type": "application/json",
                Accept: "application/json",
            },
        })
            .then(function (response) {
                return response.json();
            })
            .then(function (jsonData) {
                setModuleAttributeData(jsonData);
            });
    };

    useEffect(() => {
        getShipData();
        getFactionData();
        getObjectData();
        getObjectTypeData();
        getMissionData();
        getModuleData();
        getModuleAttributeData();
        getSpecialistsData();
        getCommanderData();
        getEffectData();
    }, []);

    const gameData = {
        shipData,
        factionData,
        objectData,
        objectTypeData,
        missionData,
        moduleData,
        moduleAttributeData,
        specialistsData,
        commanderData,
        effectData,
        release: version + (branch != "master" ? "(" + branch + ")" : "")
    };

    return gameData;
}
