import React, { useState } from "react";

import Container from "react-bootstrap/Container";
import Row from "react-bootstrap/Row";
import Col from "react-bootstrap/Col";
import VVPage from "components/containers/vvpage";

import { DiscussWidget } from "components/ui/discuss";
import { DiscussButton } from "components/ui/discuss";
import { ToggleDetail } from "components/ui/discuss";

export default function Commanders(props) {
    const commanderData = props.appData.commanderData;
    const factionData = props.appData.factionData;
    const effectData = props.appData.effectData;

    const [discordChannel, setDiscordChannel] = useState("");

    return (
        <VVPage id="commanders">
            {factionData.factions &&
                factionData.factions.length > 0 &&
                factionData.factions.map((faction) => (
                    <Row className="faction-row" key={faction.id}>
                        <Col
                            variant="dark"
                            className={""}
                        >
                            <Container>
                                <Row>
                                    <Col>
                                        <h2 className="faction-name">{faction.name}</h2>
                                    </Col>
                                </Row>

                                <Row>
                                    {commanderData.commanders &&
                                        commanderData.commanders.length > 0 &&
                                        commanderData.commanders
                                            .filter(
                                                (commander) =>
                                                    commander.faction === faction.id
                                            )
                                            .map((commander) => (
                                                <Col
                                                    xs="12"
                                                    md="6"
                                                    key={commander.id}
                                                >
                                                    <div id={commander.id} className="commander-container" style={{ backgroundImage: "linear-gradient(to bottom, #282c3400, #282c34FF),url('content/rules-assets/factions/" + faction.id + ".svg'),url('content/game-assets/commanders/" + commander.id + ".png')" }}>

                                                        <div className="commander-stats">
                                                            {commander.strategic != null ?
                                                                <div className="stat strategic">
                                                                    <div>{commander.strategic}</div>
                                                                </div>
                                                                : ""}
                                                            {commander.tactical != null ?
                                                                <div className="stat tactical">
                                                                    <div>{commander.tactical}</div>
                                                                </div>
                                                                : ""}

                                                        </div>

                                                        <div className="commander-id">
                                                            {commander.name}
                                                            <div className="commander-title">
                                                                {commander.title}
                                                            </div>

                                                        </div>

                                                        <div className="commander-effects">
                                                            {commander.effects &&
                                                                commander.effects.length > 0 &&
                                                                commander.effects.map((effect) => (
                                                                    effectData[effect] != null ?
                                                                        <div key={commander.id + effect} className={"commander-effect " + effectData[effect].rank} >
                                                                            <div className="effect-name effect-cost">
                                                                                <img src={'content/rules-assets/commanders/' + effectData[effect].rank + '.svg'} />
                                                                                <span>{effectData[effect].name}</span>
                                                                            </div>
                                                                            <div className="effect-description">
                                                                                {effectData[effect].description}
                                                                            </div>
                                                                        </div>
                                                                        : <div key={commander.id + effect}></div>
                                                                ))}
                                                        </div>
                                                        <DiscussButton item={commander} onClick={e => ToggleDetail(e,commander,setDiscordChannel)} />
                                                    </div>
                                                </Col>
                                            ))}
                                </Row>
                            </Container>
                        </Col>
                    </Row>
                ))}
                <DiscussWidget channel={discordChannel} />
        </VVPage>
    );
}
