import React, { useState, useEffect } from "react";
import { useParams } from 'react-router-dom';

import Container from "react-bootstrap/Container";
import Row from "react-bootstrap/Row";
import Col from "react-bootstrap/Col";

//import {confirm} from 'react-bootstrap-confirmation';

import VVMissionPage from "components/containers/vvmissionpage";
import MissionList from "components/containers/missionlist";
import Properties from "components/containers/properties";
import Rules from "components/containers/rules";

import SwitchAccessShortcutIcon from '@mui/icons-material/SwitchAccessShortcut';
import SettingsSuggestIcon from '@mui/icons-material/SettingsSuggest';

import * as PIXIDust from "classes/missioncanvas";

export default function Missions(props) {    
    const objectData = props.appData.objectData;
    const objectTypeData = props.appData.objectTypeData;

    const { urlMissionId } = useParams();

    const [missionListData, setMissionListData] = useState(
        props.appData.missionData
    );
    const [toolsOpen, setToolsOpen] = useState("");
    const [metaOpen, setMetaOpen] = useState("");
    const [highlightStatus, setHighlightStatus] = useState(false);
    const [whichTool, setWhichTool] = useState("");
    const [whichMeta, setWhichMeta] = useState("");
    const [objectsLocked, setObjectsLocked] = useState("");
    const [objectProperties, setObjectProperties] = useState("");
    const [missionTitle, setMissionTitle] = useState("");
    const [missionRules, setMissionRules] = useState("");

    const deleteWarn = async () => {
        if (window.confirm('Are sure you want to delete this mission?')) {
            PIXIDust.deleteMission();
        }
    };

    //add to console for debugging
    window.PIXIDust = PIXIDust;

    useEffect(() => {
        // TODO: reimplement array of functions
        function updateMissionList(title) {
            setMissionListData(Array.from(props.appData.missionData.missions));
        }    
        function updateMissionRules(title, rules) {
            setMissionTitle(title);
            setMissionRules(rules);      
        }
        function setMissionLocked(isLocked) {
            if (isLocked) { 
                setObjectsLocked("locked-tab");
            } else {
                setObjectsLocked("");
            }
        }
        function loadObjectProps(objectProps) {
            if (objectProps != null) {
                setObjectProperties(Array.from(objectProps));
            }
        }
        function resetObjectProps() {
            setObjectProperties([]);
        }
        if (props.appData.missionData.missions != null) {
            PIXIDust.InitPixi({"updateMissionList":updateMissionList,"lockMission":setMissionLocked,"loadObjectProps":loadObjectProps,"resetObjectProps":resetObjectProps, "updateMissionRules":updateMissionRules}, props.appData.missionData,urlMissionId);
            setMissionListData(props.appData.missionData.missions);
        }
        return () => {
            PIXIDust.DestroyPixi();
        };
    }, [props.appData.missionData]);


    function toolTabState(open, whichtab) {
        if (open) {
            setToolsOpen("tools-open");
            setWhichTool(whichtab);
        } else {
            setToolsOpen("");
            setWhichTool("");
        }
    }

    function metaTabState(open, whichmeta) {
        if (open) {
            setMetaOpen("meta-open");
            setWhichMeta(whichmeta);
        } else {
            setMetaOpen("");
            setWhichMeta("");
        }
    }


    function highlightState() {
         if (highlightStatus) {
            setHighlightStatus(false);
            PIXIDust.setHighlightStatus(false);
        } else {
            setHighlightStatus(true);
            PIXIDust.setHighlightStatus(true);
        }      
    }

    function uploadChange(e) {
        const fileReader = new FileReader();
        if (e.target.files.length > 0 && e.target.files[0] != null) {
            fileReader.readAsText(e.target.files[0], "UTF-8");
            fileReader.onload = function (e) {
                PIXIDust.uploadMission(e.target.result);
                document.getElementById("mission-upload").value = "";
            };
        }
    }

    function ObjectItems(objectData,objectType) {
        return (
            <>
            {objectData.objects &&
            objectData.objects.length > 0 &&
            objectData.objects
                .filter(object => object.type === objectType)
                .sort((a, b) =>
                    a.name.toLowerCase() >
                    b.name.toLowerCase()
                        ? 1
                        : -1
                )
                .map((object) => (
                    <div
                        key={object.id}
                        className={"mission-object"}
                        onClick={() =>
                            PIXIDust.placeObjectAtCenter(
                                object.id
                            )
                        }
                        id={object.id}
                        draggable="true"
                        onDragStart={(ev) =>
                            ev.dataTransfer.setData(
                                "object",
                                ev.target.id
                            )
                        }
                        style={{
                            backgroundImage:
                                'url("/content/game-assets/objects/' +
                                object.art +
                                '")',
                        }}
                    >
                        <span>{object.name}</span>
                    </div>
                ))}
                </>
        )
    }

    return (
        <VVMissionPage id="missions">
            
                <div
                    id="canvas"
                    variant="dark"
                    className={""}
                ></div>
            
            <div id="tools-panel" className={toolsOpen + " " + whichTool}>
                <Container className={"object-list"} key="mission-object-list">
                    <Row>
                        <Col>
                            {objectTypeData.objecttypes &&
                                objectTypeData.objecttypes.length > 0 &&
                                objectTypeData.objecttypes
                                    .sort((a, b) =>
                                        a.name.toLowerCase() >
                                        b.name.toLowerCase()
                                            ? 1
                                            : -1
                                    )
                                    .map((objectType) => (
                                        <div
                                            key={objectType.type}            
                                            id={objectType.type}
                                        >
                                            <span className={"mission-object-type"}>{objectType.name}</span>
                                            {ObjectItems(objectData,objectType.type)}
                                        </div>
                                       

                                    ))}
                        </Col>
                    </Row>
                </Container>
                <MissionList
                    missionData={missionListData}
                    key="mission-item-list"
                    pixiDust={PIXIDust}
                />
                
                <input
                    key="mission-upload"
                    id="mission-upload"
                    className="d-none"
                    type="file"
                    onInput={uploadChange}
                />
            </div>
            <div id="meta-panel" className={metaOpen + " " + whichMeta}>
                <Container className={"rules-list"} key="mission-rules">
                    <Row>
                        <Col>
                            <h1>{missionTitle}</h1>
                        </Col>
                    </Row>
                    <Row>
                        <Col>
                            <Rules
                                missionRules={missionRules}
                                key="rule-list"
                                pixiDust={PIXIDust} missionLocked={objectsLocked}
                            />
                        </Col>
                    </Row>
                </Container>
                <Properties
                    objectProperties={objectProperties}
                    key="property-list"
                    pixiDust={PIXIDust}
                />
            </div>
            <div
                id="mission-title"
                contentEditable={true}
                suppressContentEditableWarning={true}
            >
                Untitled
            </div>
            <div className={"panel-tabs " + toolsOpen + " " + whichTool}>
                <div title="Tools"
                    className={"toolset"}
                    
                ><button title="Highlight"
                    id="highlight-button"
                    className={"tool-button highlight " + highlightStatus}
                    onClick={() => highlightState()}
                />
                </div>
                <div title="Close Panel"
                    className={"panel-tab close-tab " + toolsOpen}
                    onClick={() => toolTabState(false)}
                ></div>
                <div title="Objects"
                    className={"panel-tab objects-tab " + objectsLocked}
                    onClick={() => toolTabState(true, "objects")}
                ></div>
                <div title="Missions"
                    className="panel-tab missions-tab"
                    onClick={() => toolTabState(true, "missions")}
                ></div>
                
            </div>
            <div className={"meta-tabs " + metaOpen + " " + whichMeta}>
                
                <div title="Close Panel"
                    className={"meta-tab meta-close-tab " + metaOpen}
                    onClick={() => metaTabState(false)}
                ></div>
                <div title="Rules"
                    className={"meta-tab rules-tab"}
                    onClick={() => metaTabState(true, "rules")}
                ><SwitchAccessShortcutIcon className="MuiSvgIcon-fontSizeLarge"/></div>   
                <div title="Properties"
                    className="meta-tab properties-tab"
                    onClick={() => metaTabState(true, "properties")}
                ><SettingsSuggestIcon className="MuiSvgIcon-fontSizeLarge"/></div>             
            </div>
            <div className={"utility-buttons " + toolsOpen}>
                <a title="Download Mission"
                    id="download-button"
                    className="utility-button download-mission"
                ></a>
                <div title="Upload Mission"
                    className="utility-button upload-mission"
                    onClick={() =>
                        document.getElementById("mission-upload").click()
                    }
                ></div>
                <div title="Delete Mission"
                    className={"utility-button delete-mission " + objectsLocked}
                    onClick={() => deleteWarn()}
                ></div>
            </div>
        </VVMissionPage>
    );
}
