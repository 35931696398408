import React from "react";

export default function StatBlock(props) {
    const statMax = props.statMax;
    //const statValue = props.statValue;
    const statName = props.statName;

    return (
        <div className="stat-block">
            <div className="stat-name">{statName}</div>
            <div className="stat-chart">
                {Array.apply(0, Array(statMax)).map((x, i) => (
                    <span key={i + "-s"}>{i}</span>
                ))}
            </div>
        </div>
    );
}
