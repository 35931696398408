import React from 'react';

import Row from 'react-bootstrap/Row';
import Col from 'react-bootstrap/Col';
import VVPage from 'components/containers/vvpage';

export default function Rulebook() {
    let pages = [];
    for (let p = 1; p <= 32; p++) {
        pages.push(p);
    }

    return (
        <VVPage id="rulebook">
            <Row className={''}>
            {pages.map((page) => (
                <Col key={"page"+page} lg="12" xl="6" className={'rulebook-page-container'}>
                    <div className={'rulebook-page'}>
                        <img src="content/rulebook/page-1.png" alt={"Page "+page}/>
                    </div>
                </Col>
                ))}
            </Row>
        </VVPage>

    );
}