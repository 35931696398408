import React, { useState } from "react";

import WidgetBot from '@widgetbot/react-embed'

import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faComments } from '@fortawesome/free-solid-svg-icons'

export function ToggleDetail(e,item,update) {        
    e.preventDefault();
    let itemModalBack = document.getElementById("item-modal");
    let itemModalContainer = document.getElementById("item-detail-comp");

    if (itemModalBack.style.display != 'block') {
        itemModalBack.style.display = 'block';
        
    } else {
        itemModalBack.style.display = 'none';
        return;
    }

    while (itemModalContainer.childNodes.length > 0) {
        itemModalContainer.removeChild(itemModalContainer.childNodes[0]);
    }        
   
    let itemId = item.id;
    let itemTitle = item.name;
    let itemEl = document.getElementById(item.id);
    let modalItem = itemEl.cloneNode(true)       
    itemModalContainer.append(modalItem);

    update(item.discord);
}

export function DiscussButton(props) {
    return (
        <a className="comment-button" onClick={props.onClick} data-item={props.item.id} data-item-title={props.item.name+" ("+props.item.id+")"} ><FontAwesomeIcon icon={faComments} /></a> 
    );
}

export function DiscussWidget(props) {    
    const discord = props.channel;

    const toggleDetail = (e) => {        
        e.preventDefault();
        let itemModalBack = document.getElementById("item-modal");
        itemModalBack.style.display = 'none';
        return;        
    }

    return (
        <div id="item-modal" onClick={toggleDetail} >
            <div id="item-modal-container">
                <div id="item-detail-comp">
                </div>
                <div id="item-comments">
                <WidgetBot
                    server="738836572966748202"
                    channel={discord}
                />
                </div>
            </div>
        </div>
    );
}
    