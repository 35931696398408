import { visit } from "unist-util-visit";

/**
 * Transforms `textDirective` syntax tree nodes into HTML elements
 *  intended to be replaced with React elements in `<ReactMarkdown>`'s `components` prop.
 *
 * The resulting [mdast] tree is converted to
 *  a [hast] tree internally by `react-markdown`
 *  using [remark-rehype].
 *
 *
 * Helpful links:
 * - [Markdown generic directives proposal](https://talk.commonmark.org/t/generic-directives-plugins-syntax/444)
 * - [Creating remark plugins](https://unifiedjs.com/learn/guide/create-a-plugin/)
 *
 * [mdast]: https://github.com/syntax-tree/mdast
 * [hast]: https://github.com/syntax-tree/hast#nodes
 * [remark-rehype]: https://github.com/remarkjs/remark-rehype
 */
export default function customDirectives() {
  return transform;

  function transform(tree) {
    visit(tree, "textDirective", ondirective);
  }

  function ondirective(node) {
    const { name, attributes, children } = node;
    const text = children[0]?.value ?? "";

    const data = node.data || (node.data = {});
    data.hName = name;
    data.hProperties = { ...attributes, text };
  }
}
