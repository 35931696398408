import React from "react";

import Container from "react-bootstrap/Container";
import Row from "react-bootstrap/Row";
import Col from "react-bootstrap/Col";
import VVPage from "components/containers/vvpage";

export default function ModuleAttributes(props) {
    const moduleAttributeData = props.appData.moduleAttributeData;
    
    return (
        <VVPage id="module-attributes">
            <Container fluid>
                <h2>Keywords</h2>
                <Row className="">
                    <Col variant="dark" className={"module-attribute-col"}>
                        {moduleAttributeData.moduleKeywords &&
                            moduleAttributeData.moduleKeywords.length > 0 &&
                            moduleAttributeData.moduleKeywords
                            .sort((a, b) => (a.keyword > b.keyword) ? 1 : -1)
                            .map((
                                moduleKeyword
                            ) => ( 

                            <div key={moduleKeyword.id}  id={moduleKeyword.id} className={"module-keyword"} > 
                                <h3>{moduleKeyword.keyword}</h3>
                                <p>{moduleKeyword.description}</p>                                                           
                            </div>

                            ))}
                    </Col>
                </Row>
            </Container>
            <Container fluid>
                <h2>Attributes</h2>
                <Row className="">
                    <Col variant="dark" className={"module-attribute-col"} >                            
                    {moduleAttributeData.moduleAttributes &&
                        moduleAttributeData.moduleAttributes.length > 0 &&
                        moduleAttributeData.moduleAttributes
                        .sort((a, b) => (a.name > b.name) ? 1 : -1)
                        .map((
                            moduleAttribute
                        ) => ( 
                            <div key={moduleAttribute.id}  id={moduleAttribute.id} className={"module-attribute " + moduleAttribute.id + " module-attribute-container"} > 
                                <h3>{moduleAttribute.name}</h3>
                                <p>{moduleAttribute.description}</p> 
                                {(moduleAttribute.hasVisual === true ? 
                                    <img className={"module-attribute-visual"} src={"content/rules-assets/modules/attributes/visual/"+moduleAttribute.id+".png"} />
                                : <></>)}                                          
                            </div>
                        ))}
                    </Col>
                </Row>
            </Container>
        </VVPage>
    );
}