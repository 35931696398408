import React from "react";

import Navbar from "react-bootstrap/Navbar";
import Nav from "react-bootstrap/Nav";
import NavDropdown from "react-bootstrap/NavDropdown";
import Form from "react-bootstrap/Form";
import FormControl from "react-bootstrap/FormControl";
import Button from "react-bootstrap/Button";

import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faSearch } from "@fortawesome/free-solid-svg-icons";

export default function HeaderUtil() {
    return (
        <Navbar
            bg="dark"
            variant="dark"
            expand="lg"
            fixed="top"
            className="p-2 vv-nav"
        >
            <Navbar.Brand className="vv-logo" href="/#/"></Navbar.Brand>
            <Navbar.Toggle aria-controls="basic-navbar-nav" />
            <Navbar.Collapse id="basic-navbar-nav">
                <Nav className="">
                    <Nav.Item>
                        <Nav.Link href="#/ships">Ships</Nav.Link>
                    </Nav.Item>
                    <NavDropdown title="Modules" id="basic-nav-dropdown">
                        <NavDropdown.Item href="#/module-attributes">
                            Keywords &amp; Attributes
                        </NavDropdown.Item>
                        <NavDropdown.Item href="#/modules">
                            Module List
                        </NavDropdown.Item>
                    </NavDropdown>
                    <Nav.Item>
                        <Nav.Link href="#/specialists">Specialists</Nav.Link>
                    </Nav.Item>
                    <Nav.Item>
                        <Nav.Link href="#/commanders">Commanders</Nav.Link>
                    </Nav.Item>
                    <Nav.Item>
                        <Nav.Link href="#/missions">Missions</Nav.Link>
                    </Nav.Item>

                    {/* <NavDropdown title="Dropdown" id="basic-nav-dropdown">
                <NavDropdown.Item href="#action/3.1">Action</NavDropdown.Item>
                <NavDropdown.Item href="#action/3.2">Another action</NavDropdown.Item>
                <NavDropdown.Item href="#action/3.3">Something</NavDropdown.Item>
                <NavDropdown.Divider />
                <NavDropdown.Item href="#action/3.4">Separated link</NavDropdown.Item>
                </NavDropdown> */}
                </Nav>
            </Navbar.Collapse>

            <Navbar.Collapse
                id="secondary-navbar-nav"
                className="justify-content-end"
            >
                <Nav>
                    <Nav.Item>
                        <Nav.Link
                            href="https://www.precariousgames.com/games/velocity-vanguard/rules/velocity-vangaurd-rules-2023-1005.pdf"
                            download
                            target="_blank"
                            rel="noreferrer"
                        >
                            Core Rules
                        </Nav.Link>
                    </Nav.Item>
                    <Nav.Item>
                        <Nav.Link
                            href="https://www.precariousgames.com/games/velocity-vanguard/rules/velocity-vangaurd-skirmish-rules-2023-1005.pdf"
                            download
                            target="_blank"
                            rel="noreferrer"
                        >
                            Skirmish Rules
                        </Nav.Link>
                    </Nav.Item>
                    {/*<Nav.Item>
                        <Nav.Link href="#/faq">FAQ</Nav.Link>
                    </Nav.Item>
                    <Nav.Item>
                        <Form className={'d-flex nav-search'} >
                            <FormControl type="text" placeholder="Search" />
                            <Button variant="outline-success"><FontAwesomeIcon icon={faSearch} /></Button>
                        </Form>
                    </Nav.Item>*/}
                </Nav>
            </Navbar.Collapse>
        </Navbar>
    );
}
