import React, { useState } from "react";

import Container from "react-bootstrap/Container";
import Row from "react-bootstrap/Row";
import Col from "react-bootstrap/Col";
import VVPage from "components/containers/vvpage";
import StatBlock from "components/reference/statblock";

import { DiscussWidget } from "components/ui/discuss";
import { DiscussButton } from "components/ui/discuss";
import { ToggleDetail } from "components/ui/discuss";

export default function Ships(props) {
    const shipData = props.appData.shipData;
    const factionData = props.appData.factionData;

    const [discordChannel, setDiscordChannel] = useState("");

    return (
        <VVPage id="ships">
            {factionData.factions &&
                factionData.factions.length > 0 &&
                factionData.factions.map((faction) => (
                    <Row className="faction-row" key={faction.id}>
                        <Col
                            variant="dark"
                            className={""}
                        >
                            <Container fluid>
                                <Row className="faction-title">
                                    <Col>
                                        <div className="faction-emblem"><img src={"content/rules-assets/factions/"+faction.id+".svg"}></img></div>
                                        <h2 className="faction-name">{faction.name}</h2>
                                        <div className="faction-description">{faction.description}</div>
                                    </Col>
                                </Row>

                                <Row>
                                    {shipData.ships &&
                                        shipData.ships.length > 0 &&
                                        shipData.ships
                                        .filter(
                                            (ship) =>
                                                ship.faction === faction.id
                                        )
                                        .map((ship) => (
                                            <Col
                                                xs="12"
                                            
                                                md={ ship.class === 'bc' ? 12 : 6}
                                                key={ship.id} 
                                            >
                                                <div id={ship.id}  className={"ship-container ship-class-" + ship.class} style={{backgroundImage: "linear-gradient(to bottom, #282c3400, #282c34FF),url('content/rules-assets/ships/profiles/"+ship.profile+".png'),url('content/rules-assets/ships/"+ship.id+".jpg')"}} >
                                                    <div className="ship-id">
                                                        <div className="ship-name">{ship.name}</div>    
                                                        <div className="ship-class">
                                                        {shipData.shipClasses.filter((shipClass) => ship.class === shipClass.id)[0].name}   
                                                        </div>      
                                                        <div className="ship-stats">                                        
                                                            <StatBlock statName="Shields" statMax={ship.shields} />                                                        
                                                            <StatBlock statName="Hull" statMax={ship.hull} />
                                                            <StatBlock statName="Reactor" statMax={ship.reactor} />
                                                        </div>                                                         
                                                        <div className="ship-attributes">   
                                                            <div className="ship-attribute crew"><span>{ship.crew}</span></div>
                                                            <div className="ship-attribute specialists"><span>{ship.specialists}</span></div>
                                                            <div className="ship-attribute specialist-cards"><span>{ship.specialistCards}</span></div>
                                                            <div className="ship-attribute recovery"><span>{ship.recovery}</span></div>
                                                        </div>
                                                        <div className="ship-attributes">
                                                            <div className="ship-attribute capacitor"><span>{ship.capacitor}</span></div>
                                                            <div className="ship-attribute modules"><span>{ship.modules}</span></div>
                                                            <div className="ship-attribute thrust"><span>{ship.thrust}</span></div>
                                                            <div className="ship-attribute yaw"><span>{ship.yaw}</span></div>
                                                            {ship.vector > 0 &&
                                                                <div className="ship-attribute vector"><span>{ship.vector}</span></div>
                                                            }
                                                        </div>        
                                                        <DiscussButton item={ship} onClick={e => ToggleDetail(e,ship,setDiscordChannel)} />                                       
                                                    </div>
                                                   
                                                </div>
                                            </Col>
                                        ))}
                                </Row>
                            </Container>
                        </Col>
                    </Row>
                ))}
                <DiscussWidget channel={discordChannel} />

        </VVPage>
    );
}
