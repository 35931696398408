import React, { useState, useEffect } from "react";

import Container from "react-bootstrap/Container";

import RuleItemEditor from "components/containers/ruleitemeditor";

export default function Rules(props) {
    const PIXIDust = props.pixiDust;
    const missionLocked = props.missionLocked;

    const [missionRules, setMissionRules] = useState("");

    useEffect(() => {
         setMissionRules(props.missionRules);
    }, [props.missionRules]);

    return (
        <Container className={"rules-items"}>          
                <RuleItemEditor ruleContent={missionRules} pixiDust={PIXIDust} missionLocked={missionLocked}/>        
        </Container>
    );
}
