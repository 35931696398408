import React, { useState } from "react";

import Container from "react-bootstrap/Container";
import Row from "react-bootstrap/Row";
import Col from "react-bootstrap/Col";
import VVPage from "components/containers/vvpage";
import ModuleAttributesList from "components/reference/moduleattributeslist";

import { DiscussWidget } from "components/ui/discuss";
import { DiscussButton } from "components/ui/discuss";
import { ToggleDetail } from "components/ui/discuss";

export default function Modules(props) {
    const moduleData = props.appData.moduleData;
    const moduleAttributeData = props.appData.moduleAttributeData;

    const [discordChannel, setDiscordChannel] = useState("");

    function ToggleAttribute(e) {        
        e.preventDefault();
        let attributeModalBack = document.getElementById("attribute-modal");
        
        attributeModalBack.style.display = 'none';
        return;
    }

    return (
        <VVPage id="modules">
            {moduleData.moduleTypes &&
                moduleData.moduleTypes.length > 0 &&
                moduleData.moduleTypes.map((moduleType) => (
                    <Row className="module-type-row" key={moduleType.id}>
                        <Col
                            variant="dark"
                            className={""}
                        >
                            <Container fluid>
                                <Row>
                                    <Col>
                                        <h2 className="module-type-name">{moduleType.name}</h2>
                                    </Col>
                                </Row>

                                <Row>
                                    {moduleData.modules &&
                                        moduleData.modules.length > 0 &&
                                        moduleData.modules
                                        .filter(
                                            (module) =>
                                                module.type === moduleType.id
                                        )
                                        .sort((a, b) => (a.name.toLowerCase() > b.name.toLowerCase()) ? 1 : -1)
                                        .map((module) => (
                                            <Col
                                                xs="12"
                                                md="6"
                                                xl="4"
                                                key={module.id}
                                            >
                                                <div id={module.id} className={"module-container " + module.type}>
                                                    <div className="module-id">
                                                        <div className="module-reqs">
                                                            <div className="module-size">
                                                                <div>
                                                                    {module.size[0]*module.size[1]}
                                                                </div>
                                                            </div>
                                                            {module.energy != 0 ? 
                                                            <div className="energy-req">
                                                                <div>
                                                                    {module.energy}
                                                                </div>
                                                            </div>
                                                            : <></>}
                                                                                            
              
                                                        </div>
                                                        <div>{module.name}</div>
                                                        <div className="module-type">
                                                            {module.type}
                                                            {module.keywords &&
                                                                    module.keywords.length > 0 &&
                                                                    module.keywords                                                                                                                                        
                                                                    .map((keyword) => (
                                                                        <span key={keyword}>{keyword}</span>
                                                                    ))}
                                                        </div>                                                        
                                                        <ModuleAttributesList key={module.id+"-attr"} moduleAttributeData={moduleAttributeData} module={module} />                                                  
                                                        {module.modes &&
                                                                    module.modes.length > 0 &&
                                                                    module.modes                                                                                                                                        
                                                                    .map((mode) => (
                                                                        <div className="module-mode" key={mode.id+"-attr"}>
                                                                            <div className="module-mode-name">{mode.name}</div>
                                                                            <ModuleAttributesList moduleAttributeData={moduleAttributeData} module={mode} />   
                                                                        </div>
                                                                    ))}
                                                        
                                                        <DiscussButton item={module} onClick={e => ToggleDetail(e,module,setDiscordChannel)} />                           
                                                    </div>
                                                    
                                                </div>
                                                <div className="module-description">{module.description}</div>
                                            </Col>
                                        ))}
                                </Row>
                            </Container>
                        </Col>
                    </Row>
                ))}
                <DiscussWidget channel={discordChannel} />
                <div id="attribute-modal" onClick={ToggleAttribute} >
                    <div id="attribute-modal-container">
                        <div id="attribute-detail-comp">
                        </div>                        
                    </div>
                </div>
        </VVPage>
    );
}
