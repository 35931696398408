import React from "react";

export default function ModuleAttributesList(props) {
    const moduleAttributeData = props.moduleAttributeData;
    const module = props.module;


    function ToggleAttribute(e,attributeId) {   
        console.log('ToggleAttribute',attributeId);
        e.preventDefault();
        let attributeModalBack = document.getElementById("attribute-modal");
        let attributeModalContainer = document.getElementById("attribute-detail-comp");
    
        if (attributeModalBack.style.display != 'block') {
            attributeModalBack.style.display = 'block';
            
        } else {
            attributeModalBack.style.display = 'none';
            return;
        }
    
        while (attributeModalContainer.childNodes.length > 0) {
            attributeModalContainer.removeChild(attributeModalContainer.childNodes[0]);
        }        
       
        let attributeEl = document.getElementById(attributeId);
        let modalAttribute = attributeEl.cloneNode(true)       
        attributeModalContainer.append(modalAttribute);    
    }

    return (
        <div className="module-attributes">
            {moduleAttributeData.moduleAttributes &&
                moduleAttributeData.moduleAttributes.length > 0 &&
                moduleAttributeData.moduleAttributes.map(function (
                    moduleAttribute
                ) {
                    if (
                        module.attributes &&
                        module.attributes.length > 0 &&
                        module.attributes.filter(
                            (attribute) => attribute.attr === moduleAttribute.id
                        ).length > 0
                    ) {
                        return (
                            <div key={module.id + moduleAttribute.id + "t"} className={"module-attribute-wrap"}
                            id={module.id + moduleAttribute.id + "t"}
                                onClick={e => ToggleAttribute(e,module.id + moduleAttribute.id + "t")}
                                >
                            <div
                                
                                className={
                                    "module-attribute " + moduleAttribute.id
                                }
                                
                            >
                                {module.attributes &&
                                    module.attributes.length > 0 &&
                                    module.attributes
                                        .filter(
                                            (attribute) =>
                                                attribute.attr ===
                                                moduleAttribute.id
                                        )
                                        .map((attribute) => (
                                            
                                            <div
                                                key={
                                                    module.id +
                                                    attribute.id +
                                                    "b"
                                                }                                              
                                            >
                                                <div className="module-attribute-name">
                                                    {moduleAttribute.name ==
                                                    null
                                                        ? "\u00A0"
                                                        : moduleAttribute.name}
                                                </div>
                                                <div className="module-attribute-value">
                                                    {attribute.value == null
                                                        ? "\u00A0"
                                                        : attribute.value}
                                                </div>
                                                
                                            </div>

                                                                                   
                                        ))}
                            </div>
                            <div className="module-attribute-description">
                                    {moduleAttribute.description}
                            </div>
                            </div>
                        );
                    }
                })}

            {module.tokens &&
                module.tokens.length > 0 &&
                module.tokens.map((token) => (
                    <div
                        className="module-token"
                        key={module.id + token}
                        style={{
                            backgroundImage:
                                "url('content/game-assets/modules/tokens/" +
                                token +
                                ".svg')",
                        }}
                    >
                        <div>Token</div>
                    </div>
                ))}
        </div>
    );
}
