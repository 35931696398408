import React, { useState, useEffect } from "react";

import Container from "react-bootstrap/Container";

export default function Properties(props) {
    const PIXIDust = props.pixiDust;

    const [objectProps, setObjectProps] = useState([]);
    
    const updateObjectProps = (propId, propValue) => {
        
        const newProps = objectProps.map(prop => {
            if (prop.id === propId) {
                return {...prop, value: propValue};
            }
            return prop;
        })
        setObjectProps(newProps);
        PIXIDust.updateObjectProperties(newProps);
    };

    useEffect(() => {
        setObjectProps(props.objectProperties);
    }, [props.objectProperties]);

    function PropertyItem(objectPropValue) {
       
            return (<div
                key={objectPropValue.id}
                className={"property-item"}                
                 >
                <label>{objectPropValue.label}</label>
                <input id={objectPropValue.id} name={objectPropValue.id} type="text" value={objectPropValue.value}
                onChange={e => updateObjectProps(objectPropValue.id,e.target.value)}/>
            </div>);
        }
    

    return (
        <Container className={"property-list"}>           
            {objectProps &&
                objectProps.length > 0 &&
                objectProps.sort((a, b) => (a.label.toLowerCase() > b.label.toLowerCase()) ? 1 : -1).map((objectPropValue) => (PropertyItem(objectPropValue)))}

           
        </Container>
    );
}
