import React from 'react';
import logo from 'img/global/v-logo.svg';

import Row from 'react-bootstrap/Row';
import Col from 'react-bootstrap/Col';
import VVPage from 'components/containers/vvpage';
import { Container } from 'react-bootstrap';

export default function Landing() {

    if (window.matchMedia('(display-mode: standalone)').matches) {  
        // do things here
    }  

    return (
        <VVPage>

            <Row className="h-100">
                <Col className={"align-items-center d-flex"} >
                    <div className="m-auto">
                        <div>
                            <img src={logo} className="App-logo" alt="logo" />
                        </div>
                        <div id="app-disclaimer">
                            This is an alpha preview of the Velocity: Vanguard Companion App. Content may not represent final components and is subject to change.
                        </div>
                    
                    </div>
                </Col>
            </Row>

        </VVPage>

    );
}