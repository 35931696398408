import React, { useState } from "react";

import Container from "react-bootstrap/Container";
import Row from "react-bootstrap/Row";
import Col from "react-bootstrap/Col";
import VVPage from "components/containers/vvpage";

import { DiscussWidget } from "components/ui/discuss";
import { DiscussButton } from "components/ui/discuss";
import { ToggleDetail } from "components/ui/discuss";

export default function Specialists(props) {
    const specialistsData = props.appData.specialistsData;

    const [discordChannel, setDiscordChannel] = useState("");

    return (
        <VVPage id="specialists">
            <Container fluid className="p-0">
                <Row>
                    {specialistsData.specialists &&
                        specialistsData.specialists.length > 0 &&
                        specialistsData.specialists
                            .sort((a, b) =>
                                a.id >
                                b.id
                                    ? 1
                                    : -1
                            )
                            .map((specialist) => (
                                <Col id={specialist.id} 
                                    xs="12"
                                    sm="12"
                                    md="6"
                                    xl="4"
                                    key={specialist.id}
                                    className="specialist-col"
                                >
                                    <Row 
                                        className={
                                            "specialist-container"
                                        }
                                    >    <div className="specialist-cards"  >                     
                                        {specialist.levels &&
                                                specialist.levels.length > 0 &&
                                                specialist.levels
                                                .sort((a, b) =>
                                                        b.name >
                                                        a.name
                                                            ? 1
                                                            : -1
                                                    )                                                    
                                                    .map((level) => (
                                                        <Col className="levels-col p-0" key={level.name} xs="6">
                                                            <div className="specialist-card" style={{backgroundImage: "url('content/game-assets/specialists/"+level.name.toLowerCase().replace(/ /g,"-")+".png'),url('content/game-assets/specialists/level-ii-blank.png')"}}>
                                                                <img className="specialist-card-img" src={"content/game-assets/specialists/"+specialist.levels[1].name.toLowerCase().replace(/ /g,"-")+".png"} />

                                                                  
                                                            </div>
                                                        </Col>

                                                        ))}</div><DiscussButton item={specialist} onClick={e => ToggleDetail(e,specialist,setDiscordChannel)} />  
                                    </Row>
                                    
                                </Col>
                            ))}
                </Row>
            </Container>
            <DiscussWidget channel={discordChannel} />
        </VVPage>
    );
}
